import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EditDesignation = ({ onClose, onUpdate, designation }) => {
    const [formData, setFormData] = useState({
        positionName: '',
        description: '',
        departmentId: '',
        departmentName: '',
    });

    const [isLoading, setIsLoading] = useState(false);

    // Pre-fill form data when editing
    useEffect(() => {
        if (designation) {
            setFormData({
                positionName: designation.positionName || '',
                description: designation.description || '',
                departmentId: designation.departmentId || '',
                departmentName: designation.departmentName || '',
                id: designation.id || '', // Ensure the ID is included for API calls
            });
        }
    }, [designation]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_LOCAL_URL}/editpositions/${formData.id}`,
                {
                    name: formData.positionName,
                    description: formData.description,
                    departmentId: formData.departmentId,
                    departmentName: formData.departmentName,
                }
            );
            console.log('Data updated successfully:', response.data);
            // Close the modal after submission
            setTimeout(() => {
                onClose();
            }, 500);
        } catch (error) {
            console.error('Error updating data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div
            id="editDesignationModal"
            className="modal fade show"
            role="dialog"
            style={{ display: 'block', paddingRight: '17px' }}
        >
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Designation</h5>
                            <button
                                type="button"
                                className="close"
                                onClick={onClose}
                            >
                                &times;
                            </button>
                        </div>
                        <div
                            className="modal-body"
                            style={{
                                maxHeight: 'calc(100vh - 200px)',
                                overflowY: 'auto',
                            }}
                        >
                            <div className="form-group">
                                <label>
                                    Department<span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    name="departmentName"
                                    value={formData.departmentName}
                                    type="text"
                                    className="form-control"
                                    readOnly
                                    placeholder="Department Name"
                                />
                            </div>
                            <div className="form-group">
                                <label>
                                    Designation Name
                                    <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                    name="positionName"
                                    value={formData.positionName}
                                    onChange={handleChange}
                                    type="text"
                                    className="form-control"
                                    required
                                    placeholder="Designation Name"
                                />
                            </div>
                            <div className="form-group">
                                <label>Description</label>
                                <textarea
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                    className="form-control"
                                    placeholder="Description"
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Updating...' : 'Update'}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={onClose}
                            >
                                Close
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditDesignation;
