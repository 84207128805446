

import axios from 'axios';
import React, { useEffect, useState } from 'react';

const EditEmployeeModal = ({ employee, onClose, onUpdate }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [currentSection, setCurrentSection] = useState('basicInfo');
    const [formData, setFormData] = useState({
        employeeName: '',
        employeeCode: '',
        employeeEmail: '',
        employeePhone: '',
        employeeAltPhone: '',
        employeeDOB: '',
        employeeGender: '',
        employeeMaritalStatus: '',
        employeePan: '',
        employeeAadhar: '',
        employeeType: '',
        employeeAddress1: '',
        employeeCity1: '',
        employeeState1: '',
        employeePincode1: '',
        employeeAddress2: '',
        employeeCity2: '',
        employeeState2: '',
        employeePincode2: '',
        employeeBloodGroup: '',
        accountHolderName: '',
        accountNumber: '',
        bankName: '',
        ifscCode: '',
        branchName: '',
        basicSalary: '',
        vda: '',
        epf: '',
        esic: '',
        otherAllowances: '',
        grossSalary: '',
        department: '',
        position: '',
        interncontractual: '',
        joiningDate: '',
        medical: false,
        travel: false,
        insurance: false,
        fatherName: '',
        motherName: '',
        emergencyContactName1: '',
        emergencyContactRelation1: '',
        emergencyContactNumber1: '',
        emergencyContactName2: '',
        emergencyContactRelation2: '',
        emergencyContactNumber2: '',
        panCardPhoto: null,
        aadharCardPhoto: null,
        passportSizePhoto: null,
        resumePhoto: null,
        otherPhoto: null,
        marksheet10thPhoto: null,
        marksheet12thPhoto: null,
        degreePhoto: null,
        passbook_check: null,
        status: 'active',
        username: localStorage.getItem('username'),
        haveChildren: '',
        spouseName: '',
        children: [],
        childrenAges: [],
        joiningCompany: '',
        company_id: '',
        joiningOffice: '',
        office_id: ''
    });

    const [states, setStates] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [offices, setOffices] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [positions, setPositions] = useState([]);
    const [filteredPositions, setFilteredPositions] = useState([]);

    useEffect(() => {
        if (employee) {
            setFormData({ ...employee });
        }
    }, [employee]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [statesRes, companiesRes, officesRes, departmentsRes, positionsRes] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_LOCAL_URL}/states`),
                    axios.get(`${process.env.REACT_APP_LOCAL_URL}/companies`),
                    axios.get(`${process.env.REACT_APP_LOCAL_URL}/offices`),
                    axios.get(`${process.env.REACT_APP_LOCAL_URL}/departments`),
                    axios.get(`${process.env.REACT_APP_LOCAL_URL}/positions`)
                ]);
                setStates(statesRes.data);
                setCompanies(companiesRes.data);
                setOffices(officesRes.data);
                setDepartments(departmentsRes.data);
                setPositions(positionsRes.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (formData.department) {
            const filtered = positions.filter(position => position.department_id === parseInt(formData.department));
            setFilteredPositions(filtered);
        } else {
            setFilteredPositions([]);
        }
    }, [formData.department, positions]);

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;
        if (type === 'checkbox') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: checked,
            }));
        } else if (type === 'file') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0],
            }));
        } else if (name === "joiningCompany") {
            const selectedCompany = companies.find(company => company.companyName === value);
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                company_id: selectedCompany ? selectedCompany.id : prevData.company_id,
            }));
        } else if (name === "joiningOffice") {
            const selectedOffice = offices.find(office => office.officeName === value);
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                office_id: selectedOffice ? selectedOffice.id : prevData.office_id,
            }));
        } else if (name === "department") {
            const selectedDepartment = departments.find(department => department.id === parseInt(value));
            setFormData((prevData) => ({
                ...prevData,
                department: value,
                departmentName: selectedDepartment ? selectedDepartment.name : prevData.departmentName,
            }));
        } else if (name === "position") {
            const selectedPosition = filteredPositions.find(position => position.id === parseInt(value));
            setFormData((prevData) => ({
                ...prevData,
                position: value,
                positionName: selectedPosition ? selectedPosition.positionName : prevData.positionName,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };
    // Add Childrens 
    // Function to handle children changes
    const handleChildrenChange = (index, e) => {
        const { name, value } = e.target;
        const updatedChildren = [...formData.children];
        updatedChildren[index] = { ...updatedChildren[index], [name]: value };
        setFormData((prevState) => ({
            ...prevState,
            children: updatedChildren
        }));
    };

    // Function to add a new child
    const addChild = () => {
        setFormData((prevState) => ({
            ...prevState,
            children: [...prevState.children, { name: '', dob: '' }]
        }));
    };

    // Function to remove a child
    const removeChild = (index) => {
        const updatedChildren = [...formData.children];
        updatedChildren.splice(index, 1);
        setFormData((prevState) => ({
            ...prevState,
            children: updatedChildren
        }));
    };


    // Add Childrens 

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const formDataToSend = new FormData();
        Object.keys(formData).forEach(key => {
            if (key === 'children') {
                formDataToSend.append(key, JSON.stringify(formData[key]));
            } else {
                formDataToSend.append(key, formData[key]);
            }
        });

        try {
            const response = await axios.put(`${process.env.REACT_APP_LOCAL_URL}/employees/${formData.id}`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            onUpdate(response.data); // Assuming onUpdate is a function to handle updated data
            onUpdate();
            setTimeout(() => {
                onClose();
                window.location.reload();
            }, 1000); // 1 second delay
        } catch (error) {
            console.error('Error updating data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div id="editEmployeeModal" className="modal fade show" role="dialog" style={{ display: "block", paddingRight: "17px" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off" noValidate="novalidate">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Employee</h5>
                            <button type="button" className="close" onClick={onClose}>&times;</button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                            <div className="nav nav-tabs">
                                <button type="button" className={`nav-link px-2 ${currentSection === 'basicInfo' ? 'active' : ''}`} onClick={() => setCurrentSection('basicInfo')}>Basic Info</button>
                                <button type="button" className={`nav-link px-2 ${currentSection === 'jobDetails' ? 'active' : ''}`} onClick={() => setCurrentSection('jobDetails')}>Positional Info</button>
                                <button type="button" className={`nav-link px-2 ${currentSection === 'otherDetails' ? 'active' : ''}`} onClick={() => setCurrentSection('otherDetails')}>Family Info</button>
                                <button type="button" className={`nav-link px-2 ${currentSection === 'accountDetails' ? 'active' : ''}`} onClick={() => setCurrentSection('accountDetails')}>Account Details</button>
                            </div>
                            <div className="tab-content">
                                {currentSection === 'basicInfo' && (
                                    <div>
                                        <h4 className='text-dark py-2 fw-bolder'>Basic Info</h4>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Full Name <span style={{ color: "red" }}>*</span></label>
                                                <input name="employeeName" type="text" className="form-control" placeholder="Enter full name" onChange={handleChange} value={formData.employeeName} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Employee Code <span style={{ color: "red" }}>*</span></label>
                                                <input name="employeeCode" type="text" className="form-control" placeholder="Enter Employee Code" onChange={handleChange} value={formData.employeeCode} />
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Phone <span style={{ color: "red" }}>*</span></label>
                                                <input name="employeePhone" type="number" className="form-control" placeholder="Enter phone number" onChange={handleChange} value={formData.employeePhone} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Alternative Phone</label>
                                                <input name="employeeAltPhone" type="number" className="form-control" placeholder="Enter alternative phone number" onChange={handleChange} value={formData.employeeAltPhone} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Email</label>
                                                <input name="employeeEmail" type="email" className="form-control" placeholder="Enter email" onChange={handleChange} value={formData.employeeEmail} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Date of Birth <span style={{ color: "red" }}>*</span></label>
                                                <input name="employeeDOB" type="date" className="form-control" placeholder="Enter date of birth" onChange={handleChange} value={formData.employeeDOB} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Gender <span style={{ color: "red" }}>*</span></label>
                                                <select name="employeeGender" className="form-control" onChange={handleChange} value={formData.employeeGender}>
                                                    <option value="">Select Gender</option>
                                                    <option value="male">Male</option>
                                                    <option value="female">Female</option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Blood Group <span style={{ color: "red" }}>*</span></label>
                                                <select
                                                    name="employeeBloodGroup"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    value={formData.employeeBloodGroup}
                                                >
                                                    <option value="" disabled>Select Blood Group</option>
                                                    <option value="A+">A+</option>
                                                    <option value="A-">A-</option>
                                                    <option value="B+">B+</option>
                                                    <option value="B-">B-</option>
                                                    <option value="AB+">AB+</option>
                                                    <option value="AB-">AB-</option>
                                                    <option value="O+">O+</option>
                                                    <option value="O-">O-</option>
                                                    <option value="unknown">unknown</option>
                                                </select>


                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>PAN</label>
                                                <input name="employeePan" type="text" className="form-control" placeholder="Enter PAN number" onChange={handleChange} value={formData.employeePan} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Aadhar Number <span style={{ color: "red" }}>*</span></label>
                                                <input name="employeeAadhar" type="number" className="form-control" placeholder="Enter Aadhar number" onChange={handleChange} value={formData.employeeAadhar} />
                                            </div>
                                            <div className="form-row">
                                                <h6 className='fw-bold'>Current Address:-</h6>
                                                <div className="form-group col-md-12">
                                                    <label>Address <span style={{ color: "red" }}>*</span></label>
                                                    <textarea name="employeeAddress1" className="form-control" placeholder="Enter address" onChange={handleChange} value={formData.employeeAddress1} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label>State<span style={{ color: "red" }}>*</span></label>
                                                    <select
                                                        name="employeeState1"
                                                        id="employeeState1"
                                                        className="form-control"
                                                        value={formData.employeeState1}
                                                        onChange={handleChange}
                                                        required
                                                    >
                                                        <option value="" disabled hidden>Select State</option>
                                                        {states.map(employeeState1 => (
                                                            <option key={employeeState1.id} value={`${employeeState1.statename}`}>{employeeState1.statename}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label>City <span style={{ color: "red" }}>*</span></label>
                                                    <input name="employeeCity1" type="text" className="form-control" placeholder="Enter city" onChange={handleChange} value={formData.employeeCity1} />
                                                </div>

                                                <div className="form-group col-md-4">
                                                    <label>Pincode</label>
                                                    <input name="employeePincode1" type="text" className="form-control" placeholder="Enter pincode" onChange={handleChange} value={formData.employeePincode1} />
                                                </div>
                                            </div>

                                            <h6 className='fw-bold'>Permanent Address:-</h6>
                                            <div className="form-group col-md-12">
                                                <label>Address <span style={{ color: "red" }}>*</span></label>
                                                <textarea name="employeeAddress2" className="form-control" placeholder="Enter address" onChange={handleChange} value={formData.employeeAddress2} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>State<span style={{ color: "red" }}>*</span></label>
                                                <select
                                                    name="employeeState2"
                                                    id="employeeState2"
                                                    className="form-control"
                                                    value={formData.employeeState2}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value="" disabled hidden>Select State</option>
                                                    {states.map(employeeState2 => (
                                                        <option key={employeeState2.id} value={`${employeeState2.statename}`}>{employeeState2.statename}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>City <span style={{ color: "red" }}>*</span></label>
                                                <input name="employeeCity2" type="text" className="form-control" placeholder="Enter city" onChange={handleChange} value={formData.employeeCity2} />
                                            </div>

                                            <div className="form-group col-md-4">
                                                <label>Pincode</label>
                                                <input name="employeePincode2" type="text" className="form-control" placeholder="Enter pincode" onChange={handleChange} value={formData.employeePincode2} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {currentSection === 'accountDetails' && (
                                    <div>
                                        <h4 className='text-dark py-2 fw-bolder'>Account Details</h4>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Account Holder Name</label>
                                                <input name="accountHolderName" type="text" className="form-control" placeholder="Enter account holder name" onChange={handleChange} value={formData.accountHolderName} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Account Number </label>
                                                <input name="accountNumber" type="number" className="form-control" placeholder="Enter account number" onChange={handleChange} value={formData.accountNumber} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>Bank Name</label>
                                                <input name="bankName" type="text" className="form-control" placeholder="Enter bank name" onChange={handleChange} value={formData.bankName} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>IFSC Code </label>
                                                <input name="ifscCode" type="text" className="form-control" placeholder="Enter IFSC code" onChange={handleChange} value={formData.ifscCode} />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label>Branch Name</label>
                                                <input name="branchName" type="text" className="form-control" placeholder="Enter branch name" onChange={handleChange} value={formData.branchName} />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>PassBook / Check</label>
                                                <input
                                                    name="passbook_check"
                                                    type="file"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {currentSection === 'jobDetails' && (
                                    <div>
                                        <h4 className='text-dark py-2 fw-bolder'>Job Details</h4>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Department <span style={{ color: "red" }}>*</span></label>
                                                <select name="department" className="form-control" onChange={handleChange} value={formData.department}>
                                                    <option value="">Select Department</option>
                                                    {departments.map(department => (
                                                        <option key={department.id} value={department.id}>{department.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Designation <span style={{ color: "red" }}>*</span></label>
                                                <select name="position" className="form-control" onChange={handleChange} value={formData.position}>
                                                    <option value="">Select Designation</option>
                                                    {filteredPositions.map(position => (
                                                        <option key={position.id} value={position.id}>{position.positionName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Joining Company<span style={{ color: "red" }}>*</span></label>
                                                <select
                                                    name="joiningCompany"
                                                    id="joiningCompany"
                                                    className="form-control"
                                                    value={formData.joiningCompany}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Joining Company"
                                                >
                                                    <option value="" disabled hidden>Joining Company</option>
                                                    {companies.map((company) => (
                                                        <option key={company.id} value={company.companyName}>
                                                            {company.companyName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Joining Office<span style={{ color: "red" }}>*</span></label>
                                                <select
                                                    name="joiningOffice"
                                                    id="joiningOffice"
                                                    className="form-control"
                                                    value={formData.joiningOffice}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder="Joining Office"
                                                >
                                                    <option value="" disabled hidden>Joining Office</option>
                                                    {offices.map((office) => (
                                                        <option key={office.id} value={office.officeName}>
                                                            {office.officeName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Employee Type</label>
                                                <select name="employeeType" className="form-control" onChange={handleChange} value={formData.employeeType}>
                                                    <option value="" disabled>Select Client</option>
                                                    <option value="intern">Intern</option>
                                                    <option value="contractual">Contractual</option>
                                                    <option value="fulltime">Full Time</option>
                                                </select>
                                            </div>

                                            <div className="form-group col-md-6">
                                                <label>Joining Date <span style={{ color: "red" }}>*</span></label>
                                                <input name="joiningDate" type="date" className="form-control" onChange={handleChange} value={formData.joiningDate} />
                                            </div>

                                            {(formData.employeeType === 'intern' || formData.employeeType === 'contractual') && (
                                                <div className="form-group col-md-12">
                                                    <label>Intern/Contractual End Date<span style={{ color: "red" }}>*</span></label>
                                                    <input
                                                        name="interncontractual"
                                                        type="date"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        value={formData.interncontractual}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {currentSection === 'otherDetails' && (
                                    <div>
                                        <h4 className='text-dark py-2 fw-bolder'>Other Details</h4>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label>Father's Name</label>
                                                <input name="fatherName" type="text" className="form-control" placeholder="Enter father's name" onChange={handleChange} value={formData.fatherName} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label>Mother's Name</label>
                                                <input name="motherName" type="text" className="form-control" placeholder="Enter mother's name" onChange={handleChange} value={formData.motherName} />
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label>Marital Status</label>
                                                <select
                                                    name="employeeMaritalStatus"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    value={formData.employeeMaritalStatus}
                                                >
                                                    <option value="">Select Status</option>
                                                    <option value="single">Single</option>
                                                    <option value="married">Married</option>
                                                    <option value="divorced">Divorced</option>
                                                    <option value="widowed">Widowed</option>
                                                </select>
                                            </div>


                                            {(formData.employeeMaritalStatus === 'married') && (
                                                <div className="form-group col-md-12">
                                                    <label>Spouse Name</label>
                                                    <input
                                                        name="spouseName"
                                                        type="text"
                                                        placeholder="Spouse Name"
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        value={formData.spouseName}
                                                    />
                                                </div>
                                            )}


                                            <div className="form-group col-md-4">
                                                <label>Emergency Call Name (1)</label>
                                                <input
                                                    name="emergencyContactName1"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter emergency contact name"
                                                    onChange={handleChange}
                                                    value={formData.emergencyContactName1}
                                                />
                                            </div>

                                            <div className="form-group col-md-4">
                                                <label>Relation (1)</label>
                                                <select
                                                    name="emergencyContactRelation1"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    value={formData.emergencyContactRelation1}
                                                >
                                                    <option value="">Select relation</option>
                                                    <option value="Father">Father</option>
                                                    <option value="Mother">Mother</option>
                                                    <option value="Brother">Brother</option>
                                                    <option value="Sister">Sister</option>
                                                    <option value="Uncle">Uncle</option>
                                                    <option value="Aunt">Aunt</option>
                                                    <option value="Cousin">Cousin</option>
                                                    <option value="Friend">Friend</option>
                                                    {/* <!-- Add more options as needed --> */}
                                                </select>
                                            </div>

                                            <div className="form-group col-md-4">
                                                <label>Emergency call Number (1)</label>
                                                <input
                                                    name="emergencyContactNumber1"
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Enter emergency contact phone number"
                                                    onChange={handleChange}
                                                    value={formData.emergencyContactNumber1}
                                                />
                                            </div>

                                            <div className="form-group col-md-4">
                                                <label>Emergency Call Name (2)</label>
                                                <input
                                                    name="emergencyContactName2"
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter alternate emergency contact name"
                                                    onChange={handleChange}
                                                    value={formData.emergencyContactName2}
                                                />
                                            </div>

                                            <div className="form-group col-md-4">
                                                <label>Relation (2)</label>
                                                <select
                                                    name="emergencyContactRelation2"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    value={formData.emergencyContactRelation2}
                                                >
                                                    <option value="">Select relation</option>
                                                    <option value="Father">Father</option>
                                                    <option value="Mother">Mother</option>
                                                    <option value="Brother">Brother</option>
                                                    <option value="Sister">Sister</option>
                                                    <option value="Uncle">Uncle</option>
                                                    <option value="Aunt">Aunt</option>
                                                    <option value="Cousin">Cousin</option>
                                                    <option value="Friend">Friend</option>
                                                    {/* <!-- Add more options as needed --> */}
                                                </select>
                                            </div>

                                            <div className="form-group col-md-4">
                                                <label>Emergency Call Number (2)</label>
                                                <input
                                                    name="emergencyContactNumber2"
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Enter alternate emergency contact phone number"
                                                    onChange={handleChange}
                                                    value={formData.emergencyContactNumber2}
                                                />
                                            </div>

                                            <div className="form-group col-md-12">
                                                {(formData.employeeMaritalStatus === 'married' ||
                                                    formData.employeeMaritalStatus === 'divorced' ||
                                                    formData.employeeMaritalStatus === 'widowed') && (
                                                        <>
                                                            <div>
                                                                <label>Have Children?</label>
                                                                <select
                                                                    name="haveChildren"
                                                                    className="form-control"
                                                                    onChange={handleChange}
                                                                    value={formData.haveChildren}
                                                                >
                                                                    <option value="">Select</option>
                                                                    <option value="yes">Yes</option>
                                                                    <option value="no">No</option>
                                                                </select>
                                                            </div>

                                                            {formData.haveChildren === 'yes' && (
                                                                <div>
                                                                    {Array.isArray(formData.children) && formData.children.map((child, index) => (
                                                                        <div className="form-row" key={index}>
                                                                            <div className="form-group col-md-4">
                                                                                <label>Child {index + 1} Name</label>
                                                                                <input
                                                                                    name="name"
                                                                                    type="text"
                                                                                    placeholder="Child Name"
                                                                                    className="form-control"
                                                                                    onChange={(e) => handleChildrenChange(index, e)}
                                                                                    value={child.name}
                                                                                />
                                                                            </div>

                                                                            <div className="form-group col-md-4">
                                                                                <label>Child {index + 1} Date of Birth</label>
                                                                                <input
                                                                                    name="dob"
                                                                                    type="date"
                                                                                    className="form-control"
                                                                                    onChange={(e) => handleChildrenChange(index, e)}
                                                                                    value={child.dob}
                                                                                />
                                                                            </div>
                                                                            {index > 0 && (
                                                                                <div className="form-group col-md-2 d-flex align-items-end">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-danger"
                                                                                        onClick={() => removeChild(index)}
                                                                                    >
                                                                                        - Remove
                                                                                    </button>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                    <button className='btn btn-success' type="button" onClick={addChild}>Add </button>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                            </div>

                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                {isLoading ? 'Loading...' : 'Submit'}
                            </button>
                            <button type="button" className="btn btn-default" onClick={onClose}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditEmployeeModal;






