import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Sidebar from '../../components/sidebar/Sidebar';
import SearchBar from '../../components/sidebar/SearchBar';
import DepartmentAttendancePreview from './DepartmentAttendancePreview';
import { ThreeDots } from 'react-loader-spinner';  // <-- Correct import for spinner

function DepartmentAttendance({ handleLogout, username }) {
    const [isLoading, setIsLoading] = useState(false);
    const [showAttendanceDetails, setShowAttendanceDetails] = useState(false);
    const [showSidebar, setShowSidebar] = useState(true);
    const [showSearchBar, setShowSearchBar] = useState(true);
    const [departments, setDepartments] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [employees, setEmployees] = useState([]);
    const [attendanceData, setAttendanceData] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [filteredAttendance, setFilteredAttendance] = useState([]);
    const [daysInMonth, setDaysInMonth] = useState(31); // Default to 31, will be updated based on month
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [selectedRecord, setSelectedRecord] = useState({});
    const [totalPresent, setTotalPresent] = useState(0);
    const [totalAbsent, setTotalAbsent] = useState(0);
    const [totalHalfDay, setTotalHalfDay] = useState(0);
    const [totalPaidLeave, setTotalPaidLeave] = useState(0);
    const [totalUnpaidLeave, setTotalUnpaidLeave] = useState(0);
    const [totalOvertime, setTotalOvertime] = useState(0);
    const [totalWeeklyOff, setTotalWeeklyOff] = useState(0);
    const [combinedData, setCombinedData] = useState([]);

    useEffect(() => {
        const fetchDepartments = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/departments/report`);
                setDepartments(response.data);
            } catch (error) {
                console.error('Error fetching departments:', error);
            }
        };
        fetchDepartments();
    }, []);

    useEffect(() => {

        if (selectedDepartment) {
            setIsLoading(true);
            const fetchData = async () => {
                try {
                    const [employeeResponse, attendanceResponse] = await Promise.all([
                        axios.get(`${process.env.REACT_APP_LOCAL_URL}/api/employees/report?departmentId=${selectedDepartment}`),
                        axios.get(`${process.env.REACT_APP_LOCAL_URL}/attendance/report?departmentId=${selectedDepartment}`)
                    ]);
                    setEmployees(employeeResponse.data);
                    setAttendanceData(attendanceResponse.data);
                    filterAttendanceRecords(selectedMonth, selectedYear, attendanceResponse.data);
                } catch (error) {
                    console.error('Error fetching employees or attendance data:', error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchData();
        }
    }, [selectedDepartment, selectedMonth, selectedYear]);

    useEffect(() => {
        const getDaysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();
        setDaysInMonth(getDaysInMonth(selectedMonth, selectedYear));
    }, [selectedMonth, selectedYear]);

    const handleDepartmentChange = (event) => {
        setSelectedDepartment(event.target.value);
    };

    const filterAttendanceRecords = (month, year, data) => {
        const filteredRecords = data.filter(record => {
            const recordDate = new Date(record.date);
            return recordDate.getMonth() === month && recordDate.getFullYear() === year;
        });

        setFilteredAttendance(filteredRecords);
    };

    const statusMap = {
        'weekly off': 'W',
        'overtime': 'OT',
        'unpaid leave': 'UL',
        'paid leave': 'PL',
        'half day': 'H',
        'absent': 'A',
        'present': 'P'
    };

    const getAttendanceForDay = (employeeId, day) => {
        const record = filteredAttendance.find(record => {
            const recordDate = new Date(record.date);
            return record.employee_id === employeeId && recordDate.getDate() === day;
        });
        return record ? statusMap[record.status.toLowerCase()] || '' : '';
    };

    useEffect(() => {
        const calculateCombinedData = () => {
            const counts = employees.map(employee => {
                // Filter attendance records for the current employee
                const employeeAttendance = filteredAttendance.filter(record => record.employee_id === employee.id);

                // Initialize counts for each status
                const statusCounts = {
                    presentCount: 0,
                    absentCount: 0,
                    paidLeaveCount: 0,
                    halfDayCount: 0,
                    unpaidLeaveCount: 0,
                    overtimeCount: 0,
                    weeklyOffCount: 0,
                };

                // Count occurrences of each attendance status
                employeeAttendance.forEach(record => {
                    switch (record.status.toLowerCase()) { // Make comparison case-insensitive
                        case 'present':
                            statusCounts.presentCount += 1;
                            break;
                        case 'absent':
                            statusCounts.absentCount += 1;
                            break;
                        case 'paid leave':
                            statusCounts.paidLeaveCount += 1;
                            break;
                        case 'half day':
                            statusCounts.halfDayCount += 1;
                            break;
                        case 'unpaid leave':
                            statusCounts.unpaidLeaveCount += 1;
                            break;
                        case 'overtime':
                            statusCounts.overtimeCount += 1;
                            break;
                        case 'weekly off':
                            statusCounts.weeklyOffCount += 1;
                            break;
                        default:
                            break;
                    }
                });

                // Return the combined data for the current employee
                return {
                    id: employee.id,
                    employeeName: employee.employeeName,
                    ...statusCounts
                };
            });

            setCombinedData(counts);
        };

        if (employees.length && filteredAttendance.length) {
            calculateCombinedData();
        }
    }, [employees, filteredAttendance]);


    const handleDownloadAttendance = () => {
        if (selectedDepartment && filteredAttendance.length > 0) {
            // Initialize Excel data with header
            const header = ['Name', ...Array.from({ length: daysInMonth }, (_, i) => i + 1)];
            const data = [header];

            // Populate data for each employee
            employees.forEach(employee => {
                const row = [employee.employeeName];
                for (let day = 1; day <= daysInMonth; day++) {
                    const attendance = getAttendanceForDay(employee.id, day);
                    row.push(attendance);
                }
                data.push(row);
            });

            // Create a new worksheet
            const worksheet = XLSX.utils.aoa_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Department Attendance');

            // Generate a Blob object containing the XLSX file
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Trigger file download
            saveAs(blob, `DepartmentAttendance_${selectedDepartment}_${selectedMonth + 1}_${selectedYear}.xlsx`);
        } else {
            toast.error('Please select a department and apply filters before downloading.');
        }
    };

    const handleAttendanceDetails = () => {
        setSelectedRecord({
            date: new Date().toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }),
            status: filteredAttendance.length > 0 ? filteredAttendance[0].status : '',
            attendanceData: { filteredAttendance },
            selectedMonth,
            selectedYear,
            departments,
            selectedDepartment,
        });
        setShowSidebar(false); // Set to false to hide sidebar
        setShowSearchBar(false);
        setShowAttendanceDetails(true);
    };

    const handleClosePreview = () => {
        setShowSidebar(true);
        setShowSearchBar(true);
        setShowAttendanceDetails(false);
    };

    return (
        <div className='d-flex w-100 h-100 bg-white'>
            {showSidebar && <Sidebar />}
            <div className='w-100'>
                {showSearchBar && <SearchBar className="searchbarr" username={username} handleLogout={handleLogout} />}
                <div className="container-fluid">
                    <ToastContainer />
                    {showAttendanceDetails ? (
                        <DepartmentAttendancePreview
                            record={selectedRecord}
                            combinedData={combinedData}
                            onClose={handleClosePreview}
                        />
                    ) : (
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-primary">Department Attendance</h6>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <label className='pt-2 text-black fw-bolder'>Department: </label>
                                            <select
                                                id="departmentSelect"
                                                value={selectedDepartment}
                                                onChange={handleDepartmentChange}
                                                className="form-select"
                                            >
                                                <option value="">Select Department</option>
                                                {departments.map(dept => (
                                                    <option key={dept.id} value={dept.id}>
                                                        {dept.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <label className='pt-2 text-black fw-bolder'>Filter:</label>
                                            <select
                                                id="monthSelect"
                                                value={selectedMonth}
                                                onChange={e => setSelectedMonth(Number(e.target.value))}
                                                className="form-select"
                                            >
                                                {[...Array(12).keys()].map(month => (
                                                    <option key={month} value={month}>
                                                        {new Date(0, month).toLocaleString('default', { month: 'long' })}
                                                    </option>
                                                ))}
                                            </select>
                                            <select
                                                id="yearSelect"
                                                value={selectedYear}
                                                onChange={e => setSelectedYear(Number(e.target.value))}
                                                className="form-select"
                                            >
                                                {[...Array(5).keys()].map(offset => (
                                                    <option key={offset} value={new Date().getFullYear() - offset}>
                                                        {new Date().getFullYear() - offset}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center gap-1'>
                                            <button className='btn btn-success' onClick={handleDownloadAttendance}>Excel</button>
                                            <button className='btn btn-success' onClick={handleAttendanceDetails}>PDF</button>
                                        </div>
                                    </div>
                                    <div className="card-body form-row ">
                                        <div className='col-md-12'>
                                            <div style={{ maxHeight: "450px", overflowY: "auto" }}>

                                                <table className="table table-striped table-bordered" style={{ width: "100%" }}>
                                                    <thead style={{ position: "sticky", top: "0", zIndex: "1", backgroundColor: "#fff" }}>
                                                        <tr>
                                                            <th style={{ fontSize: "10px" }}>Name</th>
                                                            {[...Array(daysInMonth).keys()].map(day => (
                                                                <th style={{ fontSize: "10px" }} key={day}>{day + 1}</th>
                                                            ))}
                                                            <th style={{ fontSize: "10px" }}>Present</th>
                                                            <th style={{ fontSize: "10px" }}>Absent</th>
                                                            <th style={{ fontSize: "10px" }}>Paid Leave</th>
                                                            <th style={{ fontSize: "10px" }}>Half Day</th>
                                                            <th style={{ fontSize: "10px" }}>Unpaid Leave</th>
                                                            <th style={{ fontSize: "10px" }}>Overtime</th>
                                                            <th style={{ fontSize: "10px" }}>Weekly Off</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {isLoading ? (<div className="d-flex justify-content-center align-items-center">
                                                            {/* Correct usage of spinner */}
                                                            <ThreeDots
                                                                color="#00BFFF"
                                                                height={80}
                                                                width={80}
                                                            />
                                                        </div>
                                                        ) : (employees.length === 0 ? (
                                                            <tr>
                                                                <td colSpan="12" className="text-center">Thier is No Attendance.</td>
                                                            </tr>
                                                        ) : (employees.map(employee => (
                                                            <tr key={employee.id}>
                                                                <td style={{ fontSize: "10px" }}>{employee.employeeName}</td>
                                                                {[...Array(daysInMonth).keys()].map(day => {
                                                                    const attendance = getAttendanceForDay(employee.id, day + 1);

                                                                    // Define styles based on attendance status
                                                                    const styles = {
                                                                        'W': { backgroundColor: 'yellow', color: 'black' },
                                                                        'OT': { backgroundColor: 'lightgreen', color: 'black' },
                                                                        'UL': { backgroundColor: 'red', color: 'white' },
                                                                        'PL': { backgroundColor: 'lightgreen', color: 'black' },
                                                                        'H': { backgroundColor: 'lightblue', color: 'black' },
                                                                        'A': { backgroundColor: 'red', color: 'white' },
                                                                        'P': { backgroundColor: 'lightgreen', color: 'black' },
                                                                    };

                                                                    // Merge specific styles with default styles
                                                                    const cellStyle = {
                                                                        fontSize: "10px",
                                                                        ...styles[attendance] // Apply styles based on attendance, default is an empty object if not found
                                                                    };

                                                                    return (
                                                                        <td className='text-center' key={day} style={cellStyle}>
                                                                            {attendance}
                                                                        </td>
                                                                    );
                                                                })}
                                                                <td className='text-center' style={{ fontSize: "10px" }}>{combinedData.find(data => data.id === employee.id)?.presentCount || 0}</td>
                                                                <td className='text-center' style={{ fontSize: "10px" }}>{combinedData.find(data => data.id === employee.id)?.absentCount || 0}</td>
                                                                <td className='text-center' style={{ fontSize: "10px" }}>{combinedData.find(data => data.id === employee.id)?.halfDayCount || 0}</td>
                                                                <td className='text-center' style={{ fontSize: "10px" }}>{combinedData.find(data => data.id === employee.id)?.paidLeaveCount || 0}</td>
                                                                <td className='text-center' style={{ fontSize: "10px" }}>{combinedData.find(data => data.id === employee.id)?.unpaidLeaveCount || 0}</td>
                                                                <td className='text-center' style={{ fontSize: "10px" }}>{combinedData.find(data => data.id === employee.id)?.overtimeCount || 0}</td>
                                                                <td className='text-center' style={{ fontSize: "10px" }}>{combinedData.find(data => data.id === employee.id)?.weeklyOffCount || 0}</td>

                                                            </tr>
                                                        ))))}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DepartmentAttendance;


