import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddFamilyMembers = ({ employee, onClose, onUpdate }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        familyMemberName: '',
        relationWithFamilyMember: '',
        familyMemberDateOfBirth: '',
        familyMemberMobileNumber: '',
        familyMemberAddress: '',
    });

    // Populate formData when editing an existing employee's family details
    useEffect(() => {
        if (employee) {
            setFormData({ ...employee });
            console.log("employee",employee);
        }
    }, [employee]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Form validation
        const { familyMemberName, relationWithFamilyMember, familyMemberMobileNumber } = formData;
        if (!familyMemberName || !relationWithFamilyMember || !familyMemberMobileNumber) {
            alert('Please fill out all required fields.');
            return;
        }
        setIsLoading(true);
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_LOCAL_URL}/addfamilymembers/${employee.id}`,
                formData
            );
            console.log('Family member added successfully:', response.data);

            onUpdate(); // Refresh parent component's data
            onClose(); // Close modal
        } catch (error) {
            console.error('Error adding family member:', error);
            alert('Failed to add family member. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div
            id="addFamilyMember"
            className="modal fade show"
            role="dialog"
            style={{ display: 'block', paddingRight: '17px' }}
        >
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Family Member</h5>
                            <button type="button" className="close" onClick={onClose}>
                                &times;
                            </button>
                        </div>
                        <div
                            className="modal-body"
                            style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
                        >
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label>Member Name</label>
                                    <input
                                        name="familyMemberName"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter member's name"
                                        value={formData.familyMemberName || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Relation with Member</label>
                                    <input
                                        name="relationWithFamilyMember"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter relation (e.g., Father, Mother)"
                                        value={formData.relationWithFamilyMember || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <label>Date of Birth</label>
                                    <input
                                        name="familyMemberDateOfBirth"
                                        type="date"
                                        className="form-control"
                                        value={formData.familyMemberDateOfBirth || ''}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Mobile Number</label>
                                    <input
                                        name="familyMemberMobileNumber"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter mobile number"
                                        value={formData.familyMemberMobileNumber || ''}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-12">
                                    <label>Address</label>
                                    <textarea
                                        name="familyMemberAddress"
                                        className="form-control"
                                        placeholder="Enter address"
                                        value={formData.familyMemberAddress || ''}
                                        onChange={handleChange}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Saving...' : 'Submit'}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={onClose}
                            >
                                Close
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddFamilyMembers;
