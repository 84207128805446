// import React, { useEffect, useState, } from "react";
// import { Link, useLocation, Route, Routes, useNavigate } from "react-router-dom";
// import "./Sidebar.css";
// import AddEmployeeTable from "../../pages/EmployeeMaster/AddEmployeeTable";
// import AddVendor from "../../pages/VendorMaster/AddVendor";
// import AddCategory from "../../pages/CategoryMaster/AddCategory";
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import AddOfficeForm from "../../pages/OurOfficeMaster/AddOfficeForm";
// import AddCompany from "../../pages/Company Master/AddCompany";
// import AddDepartmentModal from "../../pages/Department_Position/AddDepartmentModal";
// import AddPositionModal from "../../pages/Department_Position/AddPositionModal";
// import AddProjectModal from "../../pages/Project_Master/AddProjectModal";
// import AddPaymentMode from "../../pages/PaymentModeMaster/AddPaymentMode";
// import AddLoan from "../../pages/LoanMaster/AddLoan";
// import AddBonusIncentive from "../../pages/Bonous_Insentive/AddBonusIncentive";
// import AddHRManager from "../../pages/EmployeeMaster/AddHRManager";
// import RequestLeave from "../../pages/LeaveSection/RequestLeave";
// import myLogo from '../../images/salary.jpg';
// import mainmaster from '../../images/icons/master.png';
// import EmployeeMaster from '../../images/icons/employeeMaster.png';
// import AttendanceMaster from '../../images/icons/attendance.png';
// import SalaryMaster from '../../images/icons/salary.jpg';
// import bonusMaster from '../../images/icons/bonus.png';
// import LeaveMaster from '../../images/icons/leave.png';
// import ReportMaster from '../../images/icons/report.png';



// // list import  

// // import { Dashboard } from "@mui/icons-material";

// function Sidebar({ handleLogout }) {
//   // Form Modal open 
//   const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
//   const [isAssetModalOpen, setIsAssetModalOpen] = useState(false); // State to manage modal open/close
//   const [isVendorModalOpen, setIsVendorModalOpen] = useState(false);


//   // Employee Modal 
//   const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
//   // Office Modal 
//   const [isAddOfficeModalOpen, setIsAddOfficeModalOpen] = useState(false);
//   // Comapny Modal 
//   const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] = useState(false);
//   // Department Modal 
//   const [isAddDepartmentModalOpen, setIsAddDepartmentModalOpen] = useState(false);
//   // add Position
//   const [isAddPositionModalOpen, setIsAddPositionModalOpen] = useState(false);
//   // Add Project  
//   const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
//   // AddPaymentMode modal 
//   const [isAddPaymentModeModalopen, setIsAddPaymentModeModalopen] = useState(false);
//   // Add Loan 
//   const [isAddLoanModalOpen, setIsAddLoanModalOpen] = useState(false);
//   // Add Bonous/Insentive
//   const [isAddBonusIncentiveModalOpen, setIsAddBonusIncentiveModalOpen] = useState(false);
//   // Add Hr Manager Form 
//   const [isAddHRManager, setIsAddHRManager] = useState(false);
//   // Add Request Leave 
//   const [isRequestLeaveModalOpen, setIsRequestLeaveModalOpen] = useState(false);





//   const location = useLocation();
//   const [style, setStyle] = useState(
//     "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
//   );

//   const changeStyle = () => {
//     if (
//       style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
//     ) {
//       setStyle(
//         "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
//       );
//     } else {
//       setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
//     }
//   };
//   const changeStyle1 = () => {
//     if (
//       style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
//     ) {
//       setStyle(
//         "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1"
//       );
//     } else {
//       setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
//     }
//   };
//   const navigate = useNavigate(); // Place the hook outside of the component


//   // Handle 
//   const handleAddAsset = () => {
//     setIsModalOpen(true); // Open the modal when "Add new Asset" button is clicked
//   };
//   // const handleAddAsset = (event) => {
//   //   event.preventDefault(); // Prevent default behavior of the anchor tag
//   //   navigate("/assetlist"); // Navigate to the asset list page
//   //   setIsModalOpen(true); // Open the modal
//   // };

//   const handleCloseModal = () => {
//     setIsModalOpen(false); // Close the modal
//   };
//   const handleAddEmployee = () => {
//     setIsEmployeeModalOpen(true);
//   };

//   const handleAddVendor = () => {
//     setIsVendorModalOpen(true);
//   };

//   const handleAddRequestLeave = () => {
//     setIsRequestLeaveModalOpen(true);
//   };

//   const handleCloseRequestLeave = () => {
//     setIsRequestLeaveModalOpen(false);
//   };

//   const handleCloseEmployeeModal = () => {
//     setIsEmployeeModalOpen(false);
//   };

//   const handleCloseVendorModal = () => {
//     setIsVendorModalOpen(false);
//   };

//   // company modal 

//   const handleAddCompany = () => {
//     setIsAddCompanyModalOpen(true);
//   };

//   const handleCloseCompanyModal = () => {
//     setIsAddCompanyModalOpen(false);
//   };

//   // Asset Lost 

//   const handleAddOffice = () => {
//     setIsAddOfficeModalOpen(true);
//   };

//   const handleCloseOfficeModal = () => {
//     setIsAddOfficeModalOpen(false);
//   };

//   // site Modal 

//   const handleAddHRManager = () => {
//     setIsAddHRManager(true)
//   };

//   const handleCloseHRManager = () => {
//     setIsAddHRManager(false);
//   };

//   // Brand  Modal 

//   const handleAddDepartment = () => {
//     setIsAddDepartmentModalOpen(true);
//   };

//   const handleCloseDepartmentModal = () => {
//     setIsAddDepartmentModalOpen(false);
//   };

//   // Add Inward Modal 

//   const handleAddPaymentMode = () => {
//     setIsAddPaymentModeModalopen(true);
//   };

//   const handleCloseAddPaymentModal = () => {
//     setIsAddPaymentModeModalopen(false);
//   };

//   // Component List Modal 

//   const handleAddLoanModal = () => {
//     setIsAddLoanModalOpen(true);
//   };

//   const handleCloseLoanModal = () => {
//     setIsAddLoanModalOpen(false);
//   };

//   // Asset Insurence Modal 

//   const handleAddBonusIncentiveModal = () => {
//     setIsAddBonusIncentiveModalOpen(true);
//   };

//   const handleCloseAddBonusIncentiveModal = () => {
//     setIsAddBonusIncentiveModalOpen(false);
//   };
//   // Asser Maintenance Modal 

//   const handleAddPositionModal = () => {
//     setIsAddPositionModalOpen(true);
//   };

//   const handleClosePositionModal = () => {
//     setIsAddPositionModalOpen(false);
//   };

//   // Project Modal 

//   const handleAddProjectModal = () => {
//     setIsAddProjectModalOpen(true);
//   };

//   const handleCloseProjectModal = () => {
//     setIsAddProjectModalOpen(false);
//   };

//   // Add Asset Modal 

//   const handleAddAssetModal = () => {
//     setIsAssetModalOpen(true);
//   };

//   const handleCloseAssetModal = () => {
//     setIsAssetModalOpen(false);
//   };

//   const handleListClick = (path) => {
//     if (window.location.pathname === path) {
//       window.location.reload();
//     }
//   };

//   const [dashboardLogo, setDashboardLogo] = useState([]);

//   useEffect(() => {
//     const fetchDashboardLogo = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
//         setDashboardLogo(response.data);
//       } catch (error) {
//         console.error('Error fetching Dashboard Logo', error);
//       }
//     };

//     fetchDashboardLogo();
//   }, []);

//   const handleUpdate = () => {
//     toast.success("successfully uploaded");
//     // window.location.reload();
//   }

//   return (
//     <div style={{ width: "50%" }} className={style} id="accordionSidebar">
//       <div id="page-top">
//         {/*  <!-- Page Wrapper --> */}
//         <ToastContainer />
//         <div id="wrapper">
//           {/*  <!-- Sidebar --> */}
//           <ul style={{ width: "50%" }} className={style} id="accordionSidebar">
//             {/*  <!-- Sidebar - Brand --> */}
//             {/* <a
//               className="sidebar-brand d-flex align-items-center justify-content-center"
//               href="#"
//             >
//               <div className="sidebar-brand-icon rotate-n-15"></div>
//               <div className="sidebar-brand-text mx-3">
//                 <div className='logoo'>
//                   <img
//                     src={dashboardLogo.landingPageLogo
//                       ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${dashboardLogo.landingPageLogo}`
//                       : <h4>HRM</h4>}
//                       className="img-logo"
//                     alt="LOGO"
//                   />
//                 </div>
//               </div>
//               <div className="text-center d-none d-md-inline">
//                 <button
//                   className="rounded-circle border-0"
//                   id="sidebarToggle"
//                   onClick={changeStyle}
//                 ></button>
//               </div>
//             </a> */}
//             <a className="sidebar-brand d-flex align-items-center justify-content-center gap-2 px-3 py-1" href="#">
//               <div className="sidebar-brand-text" style={{ width: "100%", height: "100%" }}>
//                 <div className='logoo'>
//                   <img
//                     src={dashboardLogo.landingPageLogo
//                       ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${dashboardLogo.landingPageLogo}`
//                       : myLogo}
//                     className="img-logo"
//                     alt="LOGO"
//                   />
//                 </div>
//               </div>
//               <div className="text-center d-none d-md-inline">
//                 <button className="rounded-circle border-0" id="sidebarToggle" onClick={changeStyle}></button>
//               </div>
//             </a>

//             {/*   <!-- Divider --> */}
//             <hr className="sidebar-divider my-0" />
//             {/*  <!-- Nav Item - Dashboard --> */}
//             <li className="nav-item active">
//               <Link to="/dashboard" className="nav-link">
//                 <i className="fas fa-fw fa-tachometer-alt"></i>
//                 <span>Dashboard</span>
//               </Link>
//             </li>
//             {/*  <!-- Divider --> */}
//             <hr className="sidebar-divider" />

//             {/* <!-- Nav Item - All Master --> */}
//             <li className="nav-item">
//               <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseCompany"
//                 aria-expanded="true" aria-controls="collapseCompany">
//                 <i className="fas fa-users"></i>
//                 <span>Main Master</span>
//               </a>
//               <div id="collapseCompany" className="collapse" aria-labelledby="headingClient" data-parent="#accordionSidebar">
//                 <div className="bg-white py-2 collapse-inner rounded">
//                   <h6 className="collapse-header">Company Master:</h6>
//                   <a className="collapse-item" href="#" onClick={handleAddCompany}>Add new Company </a>
//                   <Link to="/companylist" className="collapse-item" onClick={() => handleListClick("/companylist")}>
//                     <span>Company List</span>
//                   </Link>
//                   <h6 className="collapse-header">Office :</h6>
//                   <a className="collapse-item" href="#" onClick={handleAddOffice}>Add new Office</a>
//                   <Link to="/OfficeList" className="collapse-item" onClick={() => handleListClick("/OfficeList")}>
//                     <span>Office List</span>
//                   </Link>
//                   <h6 className="collapse-header">Project Master:</h6>
//                   <a className="collapse-item" href="#" onClick={handleAddProjectModal}>Add new Project </a>
//                   <Link to="/projectlist" className="collapse-item" onClick={() => handleListClick("/projectlist")}>
//                     <span>Project List</span>
//                   </Link>
//                   <h6 className="collapse-header">Department/Designation:</h6>
//                   <a className="collapse-item" href="#" onClick={handleAddDepartment} >Add new Department </a>
//                   <a className="collapse-item" href="#" onClick={handleAddPositionModal} >Add new Designation </a>

//                   <Link to="/departmentlist" className="collapse-item" onClick={() => handleListClick("/departmentlist")}>
//                     <span>Department List</span>
//                   </Link>
//                   <h6 className="collapse-header">Payment Mode Master:</h6>
//                   <a className="collapse-item" href="#" onClick={handleAddPaymentMode}>Add Payment Mode</a>
//                   <Link to="/paymentmodelist" className="collapse-item" onClick={() => handleListClick("/paymentmodelist")}>
//                     <span>Payment Mode List</span>
//                   </Link>
//                 </div>
//               </div>
//             </li>
//             {/* <!-- Nav Item - Employee --> */}
//             <li className="nav-item">
//               <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseEmployee"
//                 aria-expanded="true" aria-controls="collapseEmployee">

//                 <i className="fas fa-users"></i>
//                 <span>Employee Master</span>
//               </a>
//               <div id="collapseEmployee" className="collapse" aria-labelledby="headingEmployee" data-parent="#accordionSidebar">
//                 <div className="bg-white py-2 collapse-inner rounded">
//                   <h6 className="collapse-header">Employees:</h6>
//                   <a className="collapse-item" href="#" onClick={handleAddEmployee} >Add new Employee </a>
//                   <Link to="/employeelist" className="collapse-item" onClick={() => handleListClick("/employeelist")}>
//                     <span>Employee List</span>
//                   </Link>

//                   {/* <Link to="/resignterminationlist" className="collapse-item" onClick={() => handleListClick("/resignterminationlist")}>
//                     <span>Resign Termination List</span>
//                   </Link> */}
//                   <h6 className="collapse-header">Select HR Manager:</h6>
//                   <a className="collapse-item" href="#" onClick={handleAddHRManager} >Add Hr Manageer</a>
//                   <Link to="/hrmanagertimeline" className="collapse-item" onClick={() => handleListClick("/hrmanagertimeline")}>
//                     <span>HR Manager TimeLine</span>
//                   </Link>
//                 </div>
//               </div>
//             </li>

//             {/* <!-- Nav Item - Department --> */}
//             {/* <li className="nav-item">
//               <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseDepartment"
//                 aria-expanded="true" aria-controls="collapseDepartment">

//                 <i className="fas fa-users"></i>
//                 <span>Department Master</span>
//               </a>
//               <div id="collapseDepartment" className="collapse" aria-labelledby="headingEmployee" data-parent="#accordionSidebar">
//                 <div className="bg-white py-2 collapse-inner rounded">
//                   <h6 className="collapse-header">Department:</h6>
//                   <a className="collapse-item" href="#" onClick={handleAddDepartment} >Add new Department </a>
//                   <a className="collapse-item" href="#" onClick={handleAddPositionModal} >Add new Position </a>

//                   <Link to="/departmentlist" className="collapse-item" onClick={() => handleListClick("/departmentlist")}>
//                     <span>Department List</span>
//                   </Link>
//                 </div>
//               </div>
//             </li> */}

//             {/* Nav Item - Attendance Master*/}
//             <li className="nav-item">
//               <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAttendance"
//                 aria-expanded="true" aria-controls="collapseAttendance">
//                 <i className="fas fa-fw fa-chart-area"></i>
//                 <span>Attendance Master</span>
//               </a>
//               <div id="collapseAttendance" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
//                 <div className="bg-white py-2 collapse-inner rounded">
//                   <h6 className="collapse-header">Attendance :</h6>
//                   {/* <a className="collapse-item" href="#" onClick={handleAddDepartment}>Add Service / Product </a> */}
//                   <Link to="/addattendance" className="collapse-item" onClick={() => handleListClick("/addattendance")}>
//                     <span>Add Attendance</span>
//                   </Link>
//                   <Link to="/attendancelist" className="collapse-item" onClick={() => handleListClick("/attendancelist")}>
//                     <span>Attendance List</span>
//                   </Link>
//                 </div>
//               </div>
//             </li>
//             {/* Nav Item - salary Master*/}
//             <li className="nav-item">
//               <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsesalary"
//                 aria-expanded="true" aria-controls="collapsesalary">
//                 <i className="fas fa-fw fa-chart-area"></i>
//                 <span>salary Master</span>
//               </a>
//               <div id="collapsesalary" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
//                 <div className="bg-white py-2 collapse-inner rounded">
//                   <h6 className="collapse-header">salary :</h6>
//                   <Link to="/addsalaryslip" className="collapse-item" onClick={() => handleListClick("/addsalaryslip")}>
//                     <span>Add Salary Slip</span>
//                   </Link>
//                   <Link to="/salarylist" className="collapse-item" onClick={() => handleListClick("/salarylist")}>
//                     <span>Salary List</span>
//                   </Link>

//                   <Link to="/advancepayment" className="collapse-item" onClick={() => handleListClick("/advancepayment")}>
//                     <span>Advance Payment</span>
//                   </Link>
//                   <h6 className="collapse-header">Loan :</h6>
//                   <a className="collapse-item" href="#" onClick={handleAddLoanModal}>Add Loan</a>
//                   <Link to="/loanlist" className="collapse-item" onClick={() => handleListClick("/loanlist")}>
//                     <span>Loan List</span>
//                   </Link>
//                 </div>
//               </div>
//             </li>
//             {/* Nav Item - bonousinsentive Master*/}
//             <li className="nav-item">
//               <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsebonousinsentive"
//                 aria-expanded="true" aria-controls="collapsebonousinsentive">
//                 <i className="fas fa-fw fa-chart-area"></i>
//                 <span>Bonus/Insentive Master</span>
//               </a>
//               <div id="collapsebonousinsentive" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
//                 <div className="bg-white py-2 collapse-inner rounded">
//                   <h6 className="collapse-header">Bonus Insentive :</h6>


//                   <a className="collapse-item" href="#" onClick={handleAddBonusIncentiveModal}>Add Bonus/Incentive</a>
//                   <Link to="/bonousinsentivelist" className="collapse-item" onClick={() => handleListClick("/bonousinsentivelist")}>
//                     <span>Bonus Insentive List</span>
//                   </Link>
//                 </div>
//               </div>
//             </li>
//             {/* Nav Item - Master*/}
//             <li className="nav-item">
//               <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseLeave"
//                 aria-expanded="true" aria-controls="collapseLeave">
//                 <i className="fas fa-fw fa-chart-area"></i>
//                 <span>Leave Master</span>
//               </a>
//               <div id="collapseLeave" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
//                 <div className="bg-white py-2 collapse-inner rounded">
//                   <h6 className="collapse-header">Leave :</h6>
//                   <a className="collapse-item" href="#" onClick={handleAddRequestLeave}>Apply Leave Request</a>
//                   <Link to="/requestleavelist" className="collapse-item" onClick={() => handleListClick("/requestleavelist")}>
//                     <span>Leave Application List</span>
//                   </Link>
//                   <Link to="/allleavelist" className="collapse-item" onClick={() => handleListClick("/allleavelist")}>
//                     <span>Leave Archived</span>
//                   </Link>
//                 </div>
//               </div>
//             </li>
//             {/* Nav Item - Total Setting */}
//             <li className="nav-item">
//               <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsereport"
//                 aria-expanded="true" aria-controls="collapsereport">
//                 <i className="fa fa-cog"></i>
//                 <span>Report master</span>
//               </a>
//               <div id="collapsereport" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
//                 <div className="bg-white py-2 collapse-inner rounded">
//                   <h6 className="collapse-header">Report:</h6>

//                   <Link to="/leavereport" className="collapse-item" onClick={() => handleListClick("/leavereport")}>
//                     <span>Leave Report</span>
//                   </Link>
//                   <Link to="/loanreport" className="collapse-item" onClick={() => handleListClick("/loanreport")}>
//                     <span>loan Report</span>
//                   </Link>
//                   <Link to="/advancereport" className="collapse-item" onClick={() => handleListClick("/advancereport")}>
//                     <span>Advance Report</span>
//                   </Link>
//                   <Link to="/bonousinsentivereport" className="collapse-item" onClick={() => handleListClick("/bonousinsentivereport")}>
//                     <span>bonous/Insentive Report</span>
//                   </Link>
//                   <h6 className="collapse-header">Salary Report :</h6>
//                   <Link to="/allepfesic" className="collapse-item" onClick={() => handleListClick("/allepfesic")}>
//                     <span>EPF/ESIC/TDS Report</span>
//                   </Link>
//                   <Link to="/allsalaryreport" className="collapse-item" onClick={() => handleListClick("/allsalaryreport")}>
//                     <span>All Salary </span>
//                   </Link>
//                   <h6 className="collapse-header">Attendance Report :</h6>
//                   <Link to="/attendancelist" className="collapse-item" onClick={() => handleListClick("/attendancelist")}>
//                     <span>Employee wise</span>
//                   </Link>
//                   <Link to="/departmentattendancereport" className="collapse-item" onClick={() => handleListClick("/departmentattendancereport")}>
//                     <span>Department Wise</span>
//                   </Link>
//                   <h6 className="collapse-header">Employee In/Out Report :</h6>
//                   <Link to="/employeejoining" className="collapse-item" onClick={() => handleListClick("/employeejoining")}>
//                     <span>Employee Joining</span>
//                   </Link>
//                   <Link to="/employeeresigntermination" className="collapse-item" onClick={() => handleListClick("/employeeresigntermination")}>
//                     <span>Employee Resing/Termination</span>
//                   </Link>

//                 </div>
//               </div>
//             </li>
//             {/* Nav Item - Total Setting */}
//             <li className="nav-item">
//               <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsesetting"
//                 aria-expanded="true" aria-controls="collapsesetting">
//                 <i className="fa fa-cog"></i>
//                 <span>Setting master</span>
//               </a>
//               <div id="collapsesetting" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
//                 <div className="bg-white py-2 collapse-inner rounded">
//                   <h6 className="collapse-header">Setting:</h6>
//                   {/* <Link className="collapse-item" to="/profile" >
//                     <span>Profile Setting</span>
//                   </Link>*/}
//                   <Link className="collapse-item" to="/applicationsetting" >
//                     <span>Application Setting</span>
//                   </Link>
//                 </div>
//               </div>
//             </li>
//             {/* Nav Item - Total Delete */}
//             {/* <li className="nav-item">
//               <Link className="nav-link" to="/deletelist" >
//                 <i className="fa fa-trash"></i>
//                 <span>Trash</span>
//               </Link>
//             </li> */}
//             {/* <!-- Divider --> */}
//             <hr className="sidebar-divider d-none d-md-block" />
//           </ul>
//         </div>
//       </div>
//       {/* Add Modal Tables*/}
//       {isEmployeeModalOpen && <AddEmployeeTable onClose={handleCloseEmployeeModal} onUpdate={handleUpdate} />}
//       {isVendorModalOpen && <AddVendor onClose={handleCloseVendorModal} onUpdate={handleUpdate} />}
//       {isRequestLeaveModalOpen && <RequestLeave onClose={handleCloseRequestLeave} onUpdate={handleUpdate} />}
//       {isAddHRManager && <AddHRManager onClose={handleCloseHRManager} onUpdate={handleUpdate} />}
//       {isAddCompanyModalOpen && <AddCompany onClose={handleCloseCompanyModal} onUpdate={handleUpdate} />}
//       {isAddOfficeModalOpen && <AddOfficeForm onClose={handleCloseOfficeModal} onUpdate={handleUpdate} />}
//       {isAddDepartmentModalOpen && <AddDepartmentModal onClose={handleCloseDepartmentModal} onUpdate={handleUpdate} />}
//       {isAddPaymentModeModalopen && <AddPaymentMode onClose={handleCloseAddPaymentModal} onUpdate={handleUpdate} />}
//       {isAddLoanModalOpen && <AddLoan onClose={handleCloseLoanModal} onUpdate={handleUpdate} />}
//       {isAddBonusIncentiveModalOpen && <AddBonusIncentive onClose={handleCloseAddBonusIncentiveModal} onUpdate={handleUpdate} />}
//       {isAddPositionModalOpen && <AddPositionModal onClose={handleClosePositionModal} onUpdate={handleUpdate} />}
//       {isAddProjectModalOpen && <AddProjectModal onClose={handleCloseProjectModal} onUpdate={handleUpdate} />}
//     </div>
//   );
// }

// export default Sidebar;





import React, { useEffect, useState, } from "react";
import { Link, useLocation, Route, Routes, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import AddEmployeeTable from "../../pages/EmployeeMaster/AddEmployeeTable";
import AddVendor from "../../pages/VendorMaster/AddVendor";
import AddCategory from "../../pages/CategoryMaster/AddCategory";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddOfficeForm from "../../pages/OurOfficeMaster/AddOfficeForm";
import AddCompany from "../../pages/Company Master/AddCompany";
import AddDepartmentModal from "../../pages/Department_Position/AddDepartmentModal";
import AddPositionModal from "../../pages/Department_Position/AddPositionModal";
import AddProjectModal from "../../pages/Project_Master/AddProjectModal";
import AddPaymentMode from "../../pages/PaymentModeMaster/AddPaymentMode";
import AddLoan from "../../pages/LoanMaster/AddLoan";
import AddBonusIncentive from "../../pages/Bonous_Insentive/AddBonusIncentive";
import AddHRManager from "../../pages/EmployeeMaster/AddHRManager";
import RequestLeave from "../../pages/LeaveSection/RequestLeave";
import myLogo from '../../images/salary.jpg';
import footerLogo from '../../images/salary_logo.png';



// list import  

// import { Dashboard } from "@mui/icons-material";

function Sidebar({ handleLogout }) {
  // Form Modal open 
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal open/close
  const [isAssetModalOpen, setIsAssetModalOpen] = useState(false); // State to manage modal open/close
  const [isVendorModalOpen, setIsVendorModalOpen] = useState(false);


  // Employee Modal 
  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState(false);
  // Office Modal 
  const [isAddOfficeModalOpen, setIsAddOfficeModalOpen] = useState(false);
  // Comapny Modal 
  const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] = useState(false);
  // Department Modal 
  const [isAddDepartmentModalOpen, setIsAddDepartmentModalOpen] = useState(false);
  // add Position
  const [isAddPositionModalOpen, setIsAddPositionModalOpen] = useState(false);
  // Add Project  
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  // AddPaymentMode modal 
  const [isAddPaymentModeModalopen, setIsAddPaymentModeModalopen] = useState(false);
  // Add Loan 
  const [isAddLoanModalOpen, setIsAddLoanModalOpen] = useState(false);
  // Add Bonous/Insentive
  const [isAddBonusIncentiveModalOpen, setIsAddBonusIncentiveModalOpen] = useState(false);
  // Add Hr Manager Form 
  const [isAddHRManager, setIsAddHRManager] = useState(false);
  // Add Request Leave 
  const [isRequestLeaveModalOpen, setIsRequestLeaveModalOpen] = useState(false);





  const location = useLocation();
  const [style, setStyle] = useState(
    "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
  );

  const changeStyle = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };
  const changeStyle1 = () => {
    if (
      style == "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
    ) {
      setStyle(
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled1"
      );
    } else {
      setStyle("navbar-nav bg-gradient-primary sidebar sidebar-dark accordion");
    }
  };
  const navigate = useNavigate(); // Place the hook outside of the component


  // Handle 
  const handleAddAsset = () => {
    setIsModalOpen(true); // Open the modal when "Add new Asset" button is clicked
  };
  // const handleAddAsset = (event) => {
  //   event.preventDefault(); // Prevent default behavior of the anchor tag
  //   navigate("/assetlist"); // Navigate to the asset list page
  //   setIsModalOpen(true); // Open the modal
  // };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };
  const handleAddEmployee = () => {
    setIsEmployeeModalOpen(true);
  };

  const handleAddVendor = () => {
    setIsVendorModalOpen(true);
  };

  const handleAddRequestLeave = () => {
    setIsRequestLeaveModalOpen(true);
  };

  const handleCloseRequestLeave = () => {
    setIsRequestLeaveModalOpen(false);
  };

  const handleCloseEmployeeModal = () => {
    setIsEmployeeModalOpen(false);
  };

  const handleCloseVendorModal = () => {
    setIsVendorModalOpen(false);
  };

  // company modal 

  const handleAddCompany = () => {
    setIsAddCompanyModalOpen(true);
  };

  const handleCloseCompanyModal = () => {
    setIsAddCompanyModalOpen(false);
  };

  // Asset Lost 

  const handleAddOffice = () => {
    setIsAddOfficeModalOpen(true);
  };

  const handleCloseOfficeModal = () => {
    setIsAddOfficeModalOpen(false);
  };

  // site Modal 

  const handleAddHRManager = () => {
    setIsAddHRManager(true)
  };

  const handleCloseHRManager = () => {
    setIsAddHRManager(false);
  };

  // Brand  Modal 

  const handleAddDepartment = () => {
    setIsAddDepartmentModalOpen(true);
  };

  const handleCloseDepartmentModal = () => {
    setIsAddDepartmentModalOpen(false);
  };

  // Add Inward Modal 

  const handleAddPaymentMode = () => {
    setIsAddPaymentModeModalopen(true);
  };

  const handleCloseAddPaymentModal = () => {
    setIsAddPaymentModeModalopen(false);
  };

  // Component List Modal 

  const handleAddLoanModal = () => {
    setIsAddLoanModalOpen(true);
  };

  const handleCloseLoanModal = () => {
    setIsAddLoanModalOpen(false);
  };

  // Asset Insurence Modal 

  const handleAddBonusIncentiveModal = () => {
    setIsAddBonusIncentiveModalOpen(true);
  };

  const handleCloseAddBonusIncentiveModal = () => {
    setIsAddBonusIncentiveModalOpen(false);
  };
  // Asser Maintenance Modal 

  const handleAddPositionModal = () => {
    setIsAddPositionModalOpen(true);
  };

  const handleClosePositionModal = () => {
    setIsAddPositionModalOpen(false);
  };

  // Project Modal 

  const handleAddProjectModal = () => {
    setIsAddProjectModalOpen(true);
  };

  const handleCloseProjectModal = () => {
    setIsAddProjectModalOpen(false);
  };

  // Add Asset Modal 

  const handleAddAssetModal = () => {
    setIsAssetModalOpen(true);
  };

  const handleCloseAssetModal = () => {
    setIsAssetModalOpen(false);
  };

  const handleListClick = (path) => {
    if (window.location.pathname === path) {
      window.location.reload();
    }
  };

  const [dashboardLogo, setDashboardLogo] = useState([]);

  useEffect(() => {
    const fetchDashboardLogo = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_LOCAL_URL}/settings`);
        setDashboardLogo(response.data);
      } catch (error) {
        console.error('Error fetching Dashboard Logo', error);
      }
    };

    fetchDashboardLogo();
  }, []);

  const handleUpdate = () => {
    toast.success("successfully uploaded");
    // window.location.reload();
  }

  return (
    <div style={{ width: "50%" }} className={style} id="accordionSidebar">
      <ToastContainer />
      <div style={{ height: "100%" }} className="d-flex flex-column">
        <ul >
          {/*  <!-- Sidebar - Brand --> */}
          <a className="sidebar-brand d-flex align-items-center justify-content-center gap-2 px-3 py-1" href="#">
            <div className="sidebar-brand-text" style={{ width: "100%", height: "100%" }}>
              <div className='logoo'>
                <img
                  src={dashboardLogo.landingPageLogo
                    ? `${process.env.REACT_APP_LOCAL_URL}/uploads/settings/${dashboardLogo.landingPageLogo}`
                    : myLogo}
                  className="img-logo"
                />
              </div>
            </div>
            <div className="text-center d-none d-md-inline">
              <button className="rounded-circle border-0" id="sidebarToggle" onClick={changeStyle}></button>
            </div>
          </a>

          {/*   <!-- Divider --> */}
          <hr className="sidebar-divider my-0" />
          {/*  <!-- Nav Item - Dashboard --> */}
          <li className="nav-item active">
            <Link to="/dashboard" className="nav-link">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span>Dashboard</span>
            </Link>
          </li>
          {/*  <!-- Divider --> */}
          <hr className="sidebar-divider" />

          {/* <!-- Nav Item - All Master --> */}
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseCompany"
              aria-expanded="true" aria-controls="collapseCompany">
              <i class="fa-solid fa-cubes"></i>
              <span>Main Master</span>
            </a>
            <div id="collapseCompany" className="collapse" aria-labelledby="headingClient" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Company Master:</h6>
                <a className="collapse-item" href="#" onClick={handleAddCompany}>Add new Company </a>
                <Link to="/companylist" className="collapse-item" onClick={() => handleListClick("/companylist")}>
                  <span>Company List</span>
                </Link>
                <h6 className="collapse-header">Office :</h6>
                <a className="collapse-item" href="#" onClick={handleAddOffice}>Add new Office</a>
                <Link to="/OfficeList" className="collapse-item" onClick={() => handleListClick("/OfficeList")}>
                  <span>Office List</span>
                </Link>
                <h6 className="collapse-header">Project Master:</h6>
                <a className="collapse-item" href="#" onClick={handleAddProjectModal}>Add new Project </a>
                <Link to="/projectlist" className="collapse-item" onClick={() => handleListClick("/projectlist")}>
                  <span>Project List</span>
                </Link>
                <h6 className="collapse-header">Department/Designation:</h6>
                <a className="collapse-item" href="#" onClick={handleAddDepartment} >Add new Department </a>
                <a className="collapse-item" href="#" onClick={handleAddPositionModal} >Add new Designation </a>

                <Link to="/departmentlist" className="collapse-item" onClick={() => handleListClick("/departmentlist")}>
                  <span>Department List</span>
                </Link>
                <h6 className="collapse-header">Payment Mode Master:</h6>
                <a className="collapse-item" href="#" onClick={handleAddPaymentMode}>Add Payment Mode</a>
                <Link to="/paymentmodelist" className="collapse-item" onClick={() => handleListClick("/paymentmodelist")}>
                  <span>Payment Mode List</span>
                </Link>
              </div>
            </div>
          </li>
          {/* <!-- Nav Item - Employee --> */}
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseEmployee"
              aria-expanded="true" aria-controls="collapseEmployee">

              <i className="fas fa-users"></i>
              <span>Employee Master</span>
            </a>
            <div id="collapseEmployee" className="collapse" aria-labelledby="headingEmployee" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Employees:</h6>
                <a className="collapse-item" href="#" onClick={handleAddEmployee} >Add new Employee </a>
                <Link to="/employeelist" className="collapse-item" onClick={() => handleListClick("/employeelist")}>
                  <span>Employee List</span>
                </Link>

                {/* <Link to="/resignterminationlist" className="collapse-item" onClick={() => handleListClick("/resignterminationlist")}>
                    <span>Resign Termination List</span>
                  </Link> */}
                <h6 className="collapse-header">Select HR Manager:</h6>
                <a className="collapse-item" href="#" onClick={handleAddHRManager} >Add Hr Manageer</a>
                <Link to="/hrmanagertimeline" className="collapse-item" onClick={() => handleListClick("/hrmanagertimeline")}>
                  <span>HR Manager TimeLine</span>
                </Link>
              </div>
            </div>
          </li>

          {/* <!-- Nav Item - Department --> */}
          {/* <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseDepartment"
                aria-expanded="true" aria-controls="collapseDepartment">

                <i className="fas fa-users"></i>
                <span>Department Master</span>
              </a>
              <div id="collapseDepartment" className="collapse" aria-labelledby="headingEmployee" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Department:</h6>
                  <a className="collapse-item" href="#" onClick={handleAddDepartment} >Add new Department </a>
                  <a className="collapse-item" href="#" onClick={handleAddPositionModal} >Add new Position </a>

                  <Link to="/departmentlist" className="collapse-item" onClick={() => handleListClick("/departmentlist")}>
                    <span>Department List</span>
                  </Link>
                </div>
              </div>
            </li> */}

          {/* Nav Item - Attendance Master*/}
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAttendance"
              aria-expanded="true" aria-controls="collapseAttendance">
              <i class="fa-solid fa-clipboard-user"></i>
              <span>Attendance Master</span>
            </a>
            <div id="collapseAttendance" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Attendance :</h6>
                {/* <a className="collapse-item" href="#" onClick={handleAddDepartment}>Add Service / Product </a> */}
                <Link to="/addattendance" className="collapse-item" onClick={() => handleListClick("/addattendance")}>
                  <span>Add Attendance</span>
                </Link>
                <Link to="/addbulkattendance" className="collapse-item" onClick={() => handleListClick("/addbulkattendance")}>
                  <span>Add Bulk Attendance</span>
                </Link>
                <Link to="/addemployeeattendance" className="collapse-item" onClick={() => handleListClick("/addemployeeattendance")}>
                  <span>All Employee Attendance</span>
                </Link>
                <Link to="/attendancelist" className="collapse-item" onClick={() => handleListClick("/attendancelist")}>
                  <span>Attendance List</span>
                </Link>
              </div>
            </div>
          </li>
          {/* Nav Item - salary Master*/}
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsesalary"
              aria-expanded="true" aria-controls="collapsesalary">
              <i class="fa-solid fa-sack-dollar"></i>
              <span>salary Master</span>
            </a>
            <div id="collapsesalary" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">salary :</h6>
                <Link to="/addsalaryslip" className="collapse-item" onClick={() => handleListClick("/addsalaryslip")}>
                  <span>Add Salary Slip</span>
                </Link>
                <Link to="/salarylist" className="collapse-item" onClick={() => handleListClick("/salarylist")}>
                  <span>Salary List</span>
                </Link>

                <Link to="/advancepayment" className="collapse-item" onClick={() => handleListClick("/advancepayment")}>
                  <span>Advance Payment</span>
                </Link>
                <h6 className="collapse-header">Loan :</h6>
                <a className="collapse-item" href="#" onClick={handleAddLoanModal}>Add Loan</a>
                <Link to="/loanlist" className="collapse-item" onClick={() => handleListClick("/loanlist")}>
                  <span>Loan List</span>
                </Link>
              </div>
            </div>
          </li>
          {/* Nav Item - bonousinsentive Master*/}
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsebonousinsentive"
              aria-expanded="true" aria-controls="collapsebonousinsentive">
              <i className="fa-solid fa-hand-holding-dollar"></i>
              <span>Bonus/Insentive Master</span>
            </a>
            <div id="collapsebonousinsentive" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Bonus Insentive :</h6>


                <a className="collapse-item" href="#" onClick={handleAddBonusIncentiveModal}>Add Bonus/Incentive</a>
                <Link to="/bonousinsentivelist" className="collapse-item" onClick={() => handleListClick("/bonousinsentivelist")}>
                  <span>Bonus Insentive List</span>
                </Link>
              </div>
            </div>
          </li>
          {/* Nav Item - Master*/}
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseLeave"
              aria-expanded="true" aria-controls="collapseLeave">
               <i class="fa-solid fa-right-from-bracket"></i>
              <span>Leave Master</span>
            </a>
            <div id="collapseLeave" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Leave :</h6>
                <a className="collapse-item" href="#" onClick={handleAddRequestLeave}>Apply Leave Request</a>
                <Link to="/requestleavelist" className="collapse-item" onClick={() => handleListClick("/requestleavelist")}>
                  <span>Leave Application List</span>
                </Link>
                <Link to="/allleavelist" className="collapse-item" onClick={() => handleListClick("/allleavelist")}>
                  <span>Leave Archived</span>
                </Link>
              </div>
            </div>
          </li>
          {/* Nav Item - Total Setting */}
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsereport"
              aria-expanded="true" aria-controls="collapsereport">
             <i class="fa-solid fa-hotel"></i>
              <span>Report master</span>
            </a>
            <div id="collapsereport" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Report:</h6>

                <Link to="/leavereport" className="collapse-item" onClick={() => handleListClick("/leavereport")}>
                  <span>Leave Report</span>
                </Link>
                <Link to="/loanreport" className="collapse-item" onClick={() => handleListClick("/loanreport")}>
                  <span>loan Report</span>
                </Link>
                <Link to="/advancereport" className="collapse-item" onClick={() => handleListClick("/advancereport")}>
                  <span>Advance Report</span>
                </Link>
                <Link to="/bonousinsentivereport" className="collapse-item" onClick={() => handleListClick("/bonousinsentivereport")}>
                  <span>bonous/Insentive Report</span>
                </Link>
                <h6 className="collapse-header">Salary Report :</h6>
                <Link to="/allepfesic" className="collapse-item" onClick={() => handleListClick("/allepfesic")}>
                  <span>EPF/ESIC/TDS Report</span>
                </Link>
                <Link to="/allsalaryreport" className="collapse-item" onClick={() => handleListClick("/allsalaryreport")}>
                  <span>All Salary </span>
                </Link>
                <h6 className="collapse-header">Attendance Report :</h6>
                <Link to="/attendancelist" className="collapse-item" onClick={() => handleListClick("/attendancelist")}>
                  <span>Employee wise</span>
                </Link>
                <Link to="/departmentattendancereport" className="collapse-item" onClick={() => handleListClick("/departmentattendancereport")}>
                  <span>Department Wise</span>
                </Link>
                <h6 className="collapse-header">Employee In/Out Report :</h6>
                <Link to="/employeejoining" className="collapse-item" onClick={() => handleListClick("/employeejoining")}>
                  <span>Employee Joining</span>
                </Link>
                <Link to="/employeeresigntermination" className="collapse-item" onClick={() => handleListClick("/employeeresigntermination")}>
                  <span>Employee Resing/Termination</span>
                </Link>

              </div>
            </div>
          </li>
          {/* Nav Item - Total Setting */}
          <li className="nav-item">
            <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsesetting"
              aria-expanded="true" aria-controls="collapsesetting">
              <i className="fa fa-cog"></i>
              <span>Setting master</span>
            </a>
            <div id="collapsesetting" className="collapse" aria-labelledby="headingcategory" data-parent="#accordionSidebar">
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Setting:</h6>
                {/* <Link className="collapse-item" to="/profile" >
                    <span>Profile Setting</span>
                  </Link>*/}
                <Link className="collapse-item" to="/applicationsetting" >
                  <span>Application Setting</span>
                </Link>
              </div>
            </div>
          </li>
          {/* Nav Item - Total Delete */}
          {/* <li className="nav-item">
              <Link className="nav-link" to="/deletelist" >
                <i className="fa fa-trash"></i>
                <span>Trash</span>
              </Link>
            </li> */}
          {/* <!-- Divider --> */}
          <hr className="sidebar-divider d-none d-md-block" />

        </ul>
      </div>
      <div className="footer p-1 d-flex align-items-center justify-content-center flex-column">
        <div className='sidebar-footer'>
          <img
            src={footerLogo}
            className="img-logo"
            alt="LOGO"
          />
        </div>
        <p style={{fontSize:"10px"}} className="text-center m-0 p-0 text-white">Version 1.0 &copy; Developed by Prospect Digital</p>
      </div>

      {/* Add Modal Tables*/}
      {isEmployeeModalOpen && <AddEmployeeTable onClose={handleCloseEmployeeModal} onUpdate={handleUpdate} />}
      {isVendorModalOpen && <AddVendor onClose={handleCloseVendorModal} onUpdate={handleUpdate} />}
      {isRequestLeaveModalOpen && <RequestLeave onClose={handleCloseRequestLeave} onUpdate={handleUpdate} />}
      {isAddHRManager && <AddHRManager onClose={handleCloseHRManager} onUpdate={handleUpdate} />}
      {isAddCompanyModalOpen && <AddCompany onClose={handleCloseCompanyModal} onUpdate={handleUpdate} />}
      {isAddOfficeModalOpen && <AddOfficeForm onClose={handleCloseOfficeModal} onUpdate={handleUpdate} />}
      {isAddDepartmentModalOpen && <AddDepartmentModal onClose={handleCloseDepartmentModal} onUpdate={handleUpdate} />}
      {isAddPaymentModeModalopen && <AddPaymentMode onClose={handleCloseAddPaymentModal} onUpdate={handleUpdate} />}
      {isAddLoanModalOpen && <AddLoan onClose={handleCloseLoanModal} onUpdate={handleUpdate} />}
      {isAddBonusIncentiveModalOpen && <AddBonusIncentive onClose={handleCloseAddBonusIncentiveModal} onUpdate={handleUpdate} />}
      {isAddPositionModalOpen && <AddPositionModal onClose={handleClosePositionModal} onUpdate={handleUpdate} />}
      {isAddProjectModalOpen && <AddProjectModal onClose={handleCloseProjectModal} onUpdate={handleUpdate} />}
    </div>
  );
}

export default Sidebar;
